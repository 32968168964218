import logoBlurit from "../../static/images/logo-Blurit.svg";

export type HeaderStatus = {
    title: string;
    currentStep: boolean; // To select the current state to underline
};

export const HeaderSubscription = ({
    accountCreationStep
}: {
    accountCreationStep: HeaderStatus[];
}) => {
    return (
        <header>
            <img src={logoBlurit} alt="logo Blurit" />
            <ol>
                {accountCreationStep.map((step: HeaderStatus, index: number) => (
                    <li key={index} style={step.currentStep ? { color: "green" } : {}}>
                        {step.title}
                    </li>
                ))}
            </ol>
        </header>
    );
};
