import React, { useContext, useEffect, useState } from "react";

import "../../../../static/scss/account.scss";
import { BluritPlugin } from "./BluritPlugin";
import Invoices from "./invoices";
import { PaymentInformation } from "./PaymentInformation";
import { clientContext, Client } from "../../../../context/clientContext";
import { licensesContext } from "../../../../context/licensesContext";

export const PlanAndPayment = () => {
    const [clientValues, setClientValues] = useState<Client | null>(null);
    const { dataClient, refetchData, error } = useContext(clientContext);
    const { data: dataLicenses } = useContext(licensesContext);

    useEffect(() => {
        if (!clientValues || !dataClient || !error) {
            refetchData();
            setClientValues(dataClient);
        }
    }, [dataClient]);

    return (
        <>
            <BluritPlugin />
            <div className="mb-3" />
            <PaymentInformation clientValues={clientValues} licensesValues={dataLicenses} />
            <div className="mb-3" />
            <Invoices />
        </>
    );
};
