import React from "react";

import { Helmet as ReactHelmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// Component to update meta head dynamically
const RenderHelmet: React.FC = (): JSX.Element => {
    const { t } = useTranslation("meta");
    const Helmet = ReactHelmet;
    const suffix = "Blurit Studio";

    // Définition de l'URL canonical de la racine du site
    const canonicalUrl = `${window.location.origin}`;

    return (
        <Helmet>
            <html lang="en" />

            {/* -- Metas standards -- */}
            <title>{`${t(`title`)} | ${suffix}`}</title>
            <meta name="description" content={`${t(`description`)}`} />

            {/* -- Open Graph data -- */}
            <meta property="og:title" content={`${t(`title`)} | ${suffix}`} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:description" content={`${t(`description`)}`} />
            <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />

            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
    );
};

export default RenderHelmet;
