import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import CustomTable from "../../../../components/table/customTable/CustomTable";
import useTable from "../../../../components/table/customTable/hook/useTable";
import Pagination from "../../../../components/table/customTable/pagination/Pagination";
import { useAxios } from "../../../../services/hook/requestsService";
import { formatTable } from "../utils/tableService";

type PaginatedResponse = {
    elements: [];
    elementsPerPage: number;
    page: number;
    totalElements: number;
    totalPage: number;
};

const Invoices = () => {
    const { t } = useTranslation();
    const { initTable } = useTable();
    const { getData } = useAxios();
    const [paginationState, handlePaginationState] = useState({
        page: 1,
        elementsPerPage: 10
    });
    const [sortState, setSortState] = useState<{
        sortBy: string;
        sortOrder: "ASC" | "DESC";
    }>({ sortBy: "year", sortOrder: "ASC" });
    const [invoicesData, setInvoicesData] = useState<PaginatedResponse>({
        elements: [],
        elementsPerPage: 0,
        page: 1,
        totalElements: 0,
        totalPage: 0
    });

    useEffect(() => {
        (async () => {
            const { data } = await getData(null, "invoice", {
                params: { ...paginationState }
            });

            initTable(formatTable(t, data?.elements));
            setInvoicesData(data);
        })();
    }, [paginationState]);

    const handlePage = (action: string) => {
        handlePaginationState({
            ...paginationState,
            page: action === "+" ? paginationState?.page + 1 : paginationState?.page - 1
        });
    };

    const handleElementsPerPage = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
        handlePaginationState({
            ...paginationState,
            elementsPerPage: parseInt(target.value)
        });
    };

    const handleSort = (e: any) => {
        setSortState({
            sortBy: e.target.name,
            sortOrder: sortState.sortOrder === "ASC" ? "DESC" : "ASC"
        });
    };

    return (
        <div className="middle-container">
            <h2 className="account-title mb-3 me-lg-4">{t("account-management.invoices-title")}</h2>
            <CustomTable handleSort={handleSort} sortState={sortState} />
            {invoicesData && (
                <Pagination
                    handlePage={handlePage}
                    handleElementsPerPage={handleElementsPerPage}
                    paginationMeta={{
                        page: paginationState.page,
                        pagesCount: invoicesData.totalPage,
                        totalElements: invoicesData.totalElements,
                        elementsPerPage: invoicesData.elementsPerPage
                    }}
                />
            )}
        </div>
    );
};

export default Invoices;
