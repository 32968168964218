import moment from "moment";

const xAxisCategories = moment.monthsShort();

export const fakeData = {
    series: [
        {
            name: "Task & API Usage",
            type: "column",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: "Data Processed (Mb) ",
            type: "column",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
    ],
    options: {
        chart: {
            toolbar: {
                show: false
            },
            offsetX: -15
        },
        colors: ["#47dc95", "#285165"],
        xaxis: {
            categories: [] as string[],
            labels: {
                formatter: (value: string, index: number) => {
                    return moment().month(index).format("MMM"); // "Jan", "Feb", etc.
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        yaxis: [
            {
                seriesName: "Task & API Usage",
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: "#47dc95"
                },
                labels: {
                    style: {
                        colors: "#47dc95"
                    }
                },
                title: {
                    text: "Task & API Usage",
                    style: {
                        color: "#47dc95"
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: "Data Processed (Mb)",
                opposite: true,
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: "#285165"
                },
                labels: {
                    style: {
                        colors: "#285165"
                    }
                },
                title: {
                    text: "Data Processed (Mb)",
                    style: {
                        color: "#285165"
                    }
                }
            }
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: "topLeft",
                offsetY: 30,
                offsetX: 60
            },
            x: {
                formatter: (value: string) => {
                    return value;
                }
            }
        },
        legend: {
            horizontalAlign: "center"
        }
    }
};

export const fakeData2 = {
    series: [
        {
            name: "Task & API Usage",
            type: "column",
            data: [230, 26, 480, 89, 157, 12, 8, 0, 56, 404, 82, 96]
        },
        {
            name: "Data Processed (Mb)",
            type: "column",
            data: [1302, 4894, 6652, 270, 4578, 2231, 8896, 700, 7002, 669, 1554, 475]
        }
    ],
    options: {
        chart: {
            toolbar: {
                show: false
            },
            offsetX: -15,
            offsetY: 10,
            height: "100%"
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#47dc95", "#285165"],
        xaxis: {
            categories: [] as string[],
            labels: {
                formatter: (value: string) => {
                    return moment(value).format("dd D"); // "Mo 1", "Tu 2", etc.
                },
                style: {
                    bottom: 0
                }
            }
        },
        yaxis: [
            {
                seriesName: "Task & API Usage",
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: "#47dc95"
                },
                labels: {
                    style: {
                        colors: "#47dc95"
                    }
                },
                title: {
                    text: "Task & API Usage",
                    style: {
                        color: "#47dc95"
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: "Data Processed (Mb)",
                opposite: true,
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: "#285165"
                },
                labels: {
                    style: {
                        colors: "#285165"
                    }
                },
                title: {
                    text: "Data Processed (Mb)",
                    style: {
                        color: "#285165"
                    }
                }
            }
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: "topLeft",
                offsetY: 30,
                offsetX: 60
            },
            x: {
                formatter: (value: string) => {
                    return value;
                }
            }
        },
        legend: {
            horizontalAlign: "center"
        }
    }
};
