export const signUpTrialstates = [
    {
        title: "Sign-up",
        currentStep: true
    },
    {
        title: "Check your inbox",
        currentStep: false
    },
    {
        title: "Access free trial",
        currentStep: false
    }
];

export const CheckInboxTrialstates = [
    {
        title: "Sign-up",
        currentStep: false
    },
    {
        title: "Check your inbox",
        currentStep: true
    },
    {
        title: "Access free trial",
        currentStep: false
    }
];
