import { useLocation, useHistory } from "react-router-dom";

import { stepsTrial } from "./ressources/stepAccount";
import { StepsAccountCreation } from "./stepAccountCreation";
import { WelcomeArea } from "./welcomeArea";

type SuccessTrial = {
    mail: string;
    name: string;
};

/**
 * Success page for trial subscription
 * @returns JSX
 */
export const SubscritpionSucceedPage = () => {
    const location = useLocation<SuccessTrial | undefined>();
    const history = useHistory();
    const data = location.state;
    if (!data) history.push("/signup/usage/trial");
    const welcomeProps = {
        subscriber: data?.name,
        email: data?.mail
    };
    return (
        <div>
            <WelcomeArea props={welcomeProps} />
            <StepsAccountCreation steps={stepsTrial} />
        </div>
    );
};
