export type WelcomeAreaProps = {
    subscriber: string | undefined;
    email: string | undefined;
};

/**
 * Welcome area section for subscription success
 * @param props {subscriber {string}: name | compani_anme && email {string}: email}
 * @returns JSX
 */
export const WelcomeArea = ({ props }: { props: WelcomeAreaProps }) => {
    return (
        <section>
            <h2>Welcome {props.subscriber} !</h2>
            <h3>Check your inbox</h3>
            <p>{props.email}</p>
        </section>
    );
};
