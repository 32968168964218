import React, { useContext, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

import CustomButton from "../../components/button/button";
import CustomCalendar from "../../components/calendar/calendar";
import DataPerMonthGraph from "../../components/graphs/dataPerMonthGraph";
import TotalDataGraphs from "../../components/graphs/totalDataGraphs";
import { MobileCalendar } from "../../components/mobile/calendar/mobileCalendar";
import { SkeletonPlaceholder } from "../../components/skeleton/Skeleton";
import { customCalendarContext } from "../../context/calendarContext";
import { dashboardContext } from "../../context/DashboardContext";
import useWindowDimensions from "../../hook/useWindowDimensions";
import { ReactComponent as CallsSvg } from "../../static/icons/dashboard/calls.svg";
import { ReactComponent as ConsSvg } from "../../static/icons/dashboard/icon_download.svg";

type Option = {
    label: string;
    value: string;
};

interface IInnerCard {
    title: string;
    value: string | number;
    isLoading?: boolean;
    type: "consumption" | "apiCalls";
}

const InnerCard: React.FC<IInnerCard> = ({ title, value, type, isLoading }) => {
    const { width } = useWindowDimensions();
    let dataValue = value;
    let suffix = "";

    if (
        type === "consumption" &&
        dataValue !== "NAN" &&
        dataValue !== "-" &&
        typeof dataValue === "string"
    ) {
        if (dataValue.includes("MB")) {
            dataValue = dataValue.replace("MB", "");
            suffix = "MB";
        } else if (dataValue.includes("KB")) {
            dataValue = dataValue.replace("KB", "");
            suffix = "KB";
        } else if (dataValue.includes("GB")) {
            dataValue = dataValue.replace("GB", "");
            suffix = "GB";
        } else if (dataValue.includes("Byte")) {
            dataValue = dataValue.replace("Byte", "");
            suffix = "Byte";
        } else {
            dataValue = "";
        }
    }
    return (
        <div className={`d-flex inner-card justify-content-center align-items-center h-100`}>
            <div className="mx-4 d-none d-xl-block">
                {
                    {
                        apiCalls: <CallsSvg height={50} width={50} fill="#66C300" />,
                        consumption: <ConsSvg height={50} width={50} fill="#66C300" />
                    }[type]
                }
            </div>
            <div className="mx-0 mx-lg-4">
                <h3 className={`mb-1 title ${title === "Total" ? "total" : ""}`}>{title}</h3>
                <div className="d-flex align-items-center align-items-lg-end justify-content-xl-start justify-content-center flex-column flex-lg-row">
                    {isLoading ? (
                        <SkeletonPlaceholder
                            minHeight={"40px"}
                            width={"80px"}
                            minWidth={width < 992 ? "100%" : "100px"}
                        />
                    ) : (
                        <>
                            <h3
                                className={`data mb-0 ${type} mr-lg-1${
                                    dataValue && dataValue.toString().length > 5
                                        ? " small-number"
                                        : ""
                                }`}
                            >
                                {dataValue}
                            </h3>
                            <span
                                className={`${type} ${type !== "apiCalls" ? "d-block" : "d-none d-lg-block"}`}
                            >
                                {
                                    {
                                        apiCalls: "CALLS",
                                        consumption: `${suffix}`
                                    }[type]
                                }
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const Dashboard: React.FC = () => {
    const data = useContext(dashboardContext);
    const { t } = useTranslation();
    const [showMonthlyGraph, setShowMonthlyGraph] = useState<boolean>(true);
    const { width } = useWindowDimensions();

    const calendarState = useContext(customCalendarContext);

    // TODO: TOUT REGROUPER DANS UN CONTEXT
    const yearlyAccountsOptions = (): Option[] => {
        const options: Option[] = [];
        for (let i = moment().year(); i > 2019; i--) {
            options.push({ label: i.toString(), value: i.toString() });
        }
        return options;
    };

    const [selected, setSelected] = useState(yearlyAccountsOptions()[0]);

    const handleSelect = (selected: any) => {
        setSelected(selected);
    };
    return (
        <div className="middle-container">
            <div className="dash-mobile-container bordered mb-3">
                <div className="d-flex align-items-center gap-4 justify-content-between mb-2 mb-lg-4">
                    <h2 className="mb-0">{t("dashboard.data-processed")}</h2>
                    <CustomButton
                        onClick={() => calendarState.handleShow(true, "consumptionPerYear")}
                        classNameType="mainWhite"
                        buttonText={t("services-management.table.detail-button")}
                        withLoader={!calendarState.globalState.isLoaded}
                    />
                </div>
                <div className="row flex row">
                    <div className="col-4">
                        <InnerCard
                            title={t("dashboard.total")}
                            value={data?.apiCallsAndConsumption?.totalConsumption}
                            type="consumption"
                            isLoading={data.isLoading}
                        />
                    </div>
                    <div className="col-4">
                        <InnerCard
                            title={t("dashboard.current-month")}
                            value={data?.apiCallsAndConsumption?.totalConsumptionThisMonth}
                            type="consumption"
                            isLoading={data.isLoading}
                        />
                    </div>
                    <div className="col-4">
                        <InnerCard
                            title={t("dashboard.this-day")}
                            value={data?.apiCallsAndConsumption?.totalConsumptionThisDay}
                            type="consumption"
                            isLoading={data.isLoading}
                        />
                    </div>
                </div>
            </div>

            <div className="dash-mobile-container bordered mb-3">
                <div className="d-flex align-items-center gap-4 justify-content-between mb-2 mb-lg-4">
                    <h2 className="mb-0">{t("dashboard.task-api-usage")}</h2>
                    <CustomButton
                        onClick={() => calendarState.handleShow(true, "apiCallsPerYear")}
                        classNameType="mainWhite"
                        buttonText={t("services-management.table.detail-button")}
                        withLoader={!calendarState.globalState.isLoaded}
                    />
                </div>
                <div className="row flex row">
                    <div className="col-4">
                        <InnerCard
                            title={t("dashboard.total")}
                            value={data?.apiCallsAndConsumption?.totalCall}
                            type="apiCalls"
                            isLoading={data.isLoading}
                        />
                    </div>
                    <div className="col-4">
                        <InnerCard
                            title={t("dashboard.current-month")}
                            value={data?.apiCallsAndConsumption?.totalCallThisMonth}
                            type="apiCalls"
                            isLoading={data.isLoading}
                        />
                    </div>
                    <div className="col-4">
                        <InnerCard
                            title={t("dashboard.this-day")}
                            value={data?.apiCallsAndConsumption?.totalCallThisDay}
                            type="apiCalls"
                            isLoading={data.isLoading}
                        />
                    </div>
                </div>
            </div>

            <div className="row flex-row">
                {width < 992 && (
                    <div className="col-12 w-100 mb-3">
                        <div className="d-flex justify-content-between">
                            <button
                                type="button"
                                className={`button tab-button ${showMonthlyGraph ? "active" : ""}`}
                                onClick={() => setShowMonthlyGraph(true)}
                            >
                                {t("dashboard.total-per-year-graph.title-mobile")}
                            </button>

                            <button
                                type="button"
                                className={`button tab-button ${!showMonthlyGraph ? "active" : ""}`}
                                onClick={() => setShowMonthlyGraph(false)}
                            >
                                {t("dashboard.total-per-month-graph.title-mobile")}
                            </button>
                        </div>
                    </div>
                )}

                <div className="col-12 col-xl-6 mb-3 mb-xl-0">
                    <div className={`inner-card calendar`} hidden={!showMonthlyGraph}>
                        <TotalDataGraphs
                            service={data?.selected?.value}
                            selected={selected}
                            options={yearlyAccountsOptions()}
                            handleSelect={handleSelect}
                        />
                    </div>
                </div>

                <div className="col-12 col-xl-6">
                    <div className={`inner-card calendar`} hidden={showMonthlyGraph}>
                        <DataPerMonthGraph
                            service={data?.selected?.value}
                            currentYear={selected.value}
                        />
                    </div>
                </div>
            </div>

            {width > 992 ? <CustomCalendar /> : <MobileCalendar />}
        </div>
    );
};

export default Dashboard;
