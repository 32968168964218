import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { Formik, Form, Field, FormikValues } from "formik";
import { useHistory } from "react-router";

import { instance } from "../../api/axios";
import { ErrorResponse } from "../../api/types";
import ErrorComponent from "../../components/error/errorComponent";

export interface InitialValuesProps extends FormikValues {
    contactLastName: string;
    contactFirstName: string;
    contactEmail: string;
    validationCGU: boolean;
    validationOptIn?: boolean;
}

export const initialValues: InitialValuesProps = {
    captcha: `${process.env.RECAPTCHA_CRED}`, // TODO Catptcha unset for now
    contactLastName: "",
    contactFirstName: "",
    contactEmail: "",
    validationCGU: false,
    validationOptIn: false
};

export const TrialSubscription = () => {
    const history = useHistory();
    const [responseStatus, setResponseStatus] = useState<{
        isError: boolean;
        errorContent: ErrorResponse | unknown;
    }>({
        isError: false,
        errorContent: {}
    });
    const mutation = useMutation({
        mutationFn: async (params: InitialValuesProps) => {
            const response = await instance.post(
                `${process.env.REACT_APP_API_URL}/blurit/app/subscribe/trial`,
                params
            );
            return response.data;
        },
        onSuccess: async (clientData) => {
            history.push(`/signup/usage/trial/succeed`, clientData);
        }
    });

    return (
        <div>
            <div>
                <h2>Sign Up Get Free Trial</h2>
                <p>Sign up now and claim your free credits - no limits, no risk!</p>
                <ul>
                    <li>Unlock Blurit Studio & Api Access</li>
                    <li>No credit Card required</li>
                    <li>Get 100 MB of Processing for Free</li>
                </ul>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await mutation.mutateAsync(values);
                    } catch (error) {
                        setResponseStatus({
                            isError: true,
                            errorContent: error
                        });
                        setSubmitting(false);
                    }
                }}
            >
                <Form>
                    <Field
                        name="contactFirstName"
                        placeholder="First Name"
                        autoComplete="given-name"
                        autoFocus
                        required
                    />
                    <Field
                        name="contactLastName"
                        placeholder="Last Name"
                        autoComplete="family-name"
                        required
                    />
                    <Field name="contactEmail" placeholder="Email" required />
                    <label>
                        <Field type="checkbox" name="validationCGU" required />I accept the{" "}
                        <a
                            href={`${process.env.REACT_APP_BLURIT_LANDING_FRONT_ENDPOINT}/documents/wis-conditions.pdf`}
                        >
                            Terms & Conditions and the Data Processing Agreement *
                        </a>
                        .
                    </label>
                    <label>
                        <Field type="checkbox" name="validationOptIn" />
                        <p>
                            I agree to receive marketing emails, with the option to unsubscribe
                            anytime
                        </p>
                    </label>
                    <button type="submit">Submit</button>
                </Form>
            </Formik>
            {responseStatus.isError ? (
                <ErrorComponent error={responseStatus.errorContent} />
            ) : (
                <></>
            )}
        </div>
    );
};
