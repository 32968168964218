export type StepAccountCreationProps = {
    steps: string[];
};

export type StepAccountProps = {
    step: string;
};

export const StepAccount = ({ step }: StepAccountProps) => {
    return <li>{step}</li>;
};

export const StepsAccountCreation = ({ steps }: StepAccountCreationProps) => {
    return (
        <section>
            <p>Finalize your account creation by following the email instructions</p>
            <ol>
                {steps.map((step, index) => (
                    <StepAccount key={index} step={step} />
                ))}
            </ol>
        </section>
    );
};
