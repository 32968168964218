import React, { useState, useEffect, useContext } from "react";

import moment from "moment";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import { fakeData as graphOptions } from "./graphData";
import { SkeletonGraph } from "../../components/skeleton/Skeleton";
import { clientContext } from "../../context/clientContext";
import { formatTotalApiCallsAndConsumptionGraph } from "../../services/dashboardService";
import { useAxios } from "../../services/hook/requestsService";
import CustomSelect from "../select/CustomSelect";

type Option = {
    label: string;
    value: string;
};
interface ITotalGraph {
    service: string;
    selected: Option;
    options: Option[];
    handleSelect: (selected: unknown) => void;
}

const TotalDataGraphs: React.FC<ITotalGraph> = ({ service, selected, handleSelect, options }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [graphSeries, setGraphSeries] = useState<any | null>(graphOptions);
    const clientState = useContext(clientContext);
    const { t } = useTranslation();
    const { getData } = useAxios();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const perYearData = await getData(null, "history-call/consumption", {
                params: {
                    service: service !== "all" ? service : undefined,
                    period: "month",
                    dateStart: selected.value,
                    dateEnd: moment(selected.value).endOf("year").format("YYYY-MM-DDTHH:mm:ss"),
                    isTrial: clientState.dataClient?.isTrial
                }
            });
            const { yearApiCalls, yearConsumption } = formatTotalApiCallsAndConsumptionGraph(
                perYearData.data?.totalCountsPerPeriod || [],
                selected.value
            );
            const graphOptionsTmp = { ...graphOptions };

            // Mise à jour des données du graphs avec les dates incluant mois + année
            moment.months().forEach((month, index) => {
                graphOptionsTmp.options.xaxis.categories[index] =
                    `${month} ${moment(selected.value).year()}`;
            });

            graphOptionsTmp.series[0].data = yearApiCalls;
            graphOptionsTmp.series[1].data = yearConsumption;

            setGraphSeries(graphOptionsTmp);

            setIsLoading(false);
        })();
    }, [selected, service]);

    return (
        <div className="w-100 h-100">
            <div className="d-flex justify-content-between mx-4 mb-3">
                <div className="d-none d-lg-flex align-items-center">
                    <h2 className="mb-0 mr-1">{t("dashboard.total-per-year-graph.title")}</h2>
                </div>
                <div style={{ minWidth: "90px" }} className="w-50-mobile ms-auto">
                    <CustomSelect onChange={handleSelect} options={options} value={selected} />
                </div>
            </div>
            {isLoading ? (
                <SkeletonGraph colCount={30} />
            ) : (
                //@ts-ignore
                <Chart
                    //@ts-ignore
                    options={graphOptions.options}
                    series={graphSeries?.series}
                    type="bar"
                    width="100%"
                    height="85%"
                />
            )}
        </div>
    );
};

export default TotalDataGraphs;
