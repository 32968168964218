import React from "react";

import "../../static/scss/sidebar.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import logoWis from "../../static/images/logo-Blurit.svg";

const SidebarPlugin: React.FC = () => {
    const { t } = useTranslation();

    const navBarItems = [
        {
            linkTo: "/plugin/ressources",
            title: t("navbar.ressources"),
            icon: "icon-ressources",
            translation: t("navbar.ressources")
        },
        {
            linkTo: "/plugin/account",
            title: t("navbar.account"),
            icon: "icon-account",
            translation: t("navbar.account")
        }
    ];

    return (
        <aside className="sidebar-container d-none d-lg-block d-xl-block">
            <div className="wis-logo-container mb-3">
                <img className="w-100" src={logoWis} alt="logo Blurit Studio" />
            </div>
            <nav className="nav" role="navigation" aria-label="Aside navigation">
                <ul>
                    {navBarItems.map((item, key) => (
                        <li key={key}>
                            <NavLink
                                key={key}
                                to={item.linkTo}
                                title={item.title}
                                className={item.icon}
                                activeClassName="active"
                            >
                                <span>{item.translation}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </aside>
    );
};

export default SidebarPlugin;
