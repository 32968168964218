import React, { useState, useEffect, useContext } from "react";

import moment from "moment";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import { fakeData2 as data } from "./graphData";
import { SkeletonGraph } from "../../components/skeleton/Skeleton";
import { clientContext } from "../../context/clientContext";
import { formatDataPerMonthGraph } from "../../services/dashboardService";
import { useAxios } from "../../services/hook/requestsService";
import CustomSelect from "../select/CustomSelect";

interface IDataPerMonthGraph {
    service: string;
    currentYear: string;
}

type Option = {
    label: string;
    value: string;
};

const DataPerMonthGraph: React.FC<IDataPerMonthGraph> = ({ service, currentYear }) => {
    const options: Option[] = moment
        .months()
        .map((month) => ({ label: month, value: moment().month(month).format("MM") }));

    const currentMonth = { label: moment().format("MMMM"), value: moment().format("MM") };

    const [selected, setSelected] = useState(currentMonth);
    const [isLoading, setIsLoading] = useState(true);
    const [graphData, setGraphData] = useState<any | null>(null);
    const clientState = useContext(clientContext);
    const { getData } = useAxios();
    const { t } = useTranslation();

    // TODO: REFAIRE AVEC UN CONTEXT WALLAH
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const month = `${currentYear}-${selected?.value}`;

            const perMonthData = await getData(null, "history-call/consumption", {
                params: {
                    service: service !== "all" ? service : undefined,
                    period: "day",
                    dateStart: moment(month).startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
                    dateEnd: moment(month).endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
                    isTrial: clientState.dataClient?.isTrial
                }
            });
            const { monthConsumption, monthApiCall } = formatDataPerMonthGraph(
                perMonthData.data?.totalCountsPerPeriod,
                month
            );
            const graphOptionsTmp = { ...data };

            // Mise à jour des données du graphs avec les dates incluant jour + mois + année
            const daysInMonth = moment(month).daysInMonth();
            Array.from({ length: daysInMonth }, (_, i) => i + 1).forEach((day, index) => {
                graphOptionsTmp.options.xaxis.categories[index] =
                    `${moment(`${month}-${day}`).format("dddd")} ${day} ${moment(month).format("MMMM")} ${moment(month).year()}`;
            });

            graphOptionsTmp.series[0].data = monthConsumption;
            graphOptionsTmp.series[1].data = monthApiCall;

            setGraphData(graphOptionsTmp);

            setIsLoading(false);
        })();
        return () => {
            setGraphData(null);
            setIsLoading(false);
        };
    }, [service, currentYear, selected]);

    const handleSelect = (selected: any) => {
        setSelected(selected);
    };

    return (
        <div className="w-100 h-100">
            <div className="d-flex justify-content-between mx-4 mb-3">
                <div className="d-none d-lg-flex align-items-center">
                    <h2 className="mb-0 mr-1">{t("dashboard.total-per-month-graph.title")}</h2>
                </div>

                <div style={{ minWidth: "135px" }} className="w-50-mobile ms-auto">
                    <CustomSelect onChange={handleSelect} options={options} value={selected} />
                </div>
            </div>
            {isLoading ? (
                <SkeletonGraph colCount={30} />
            ) : (
                // @ts-ignore
                <Chart
                    //@ts-ignore
                    options={graphData.options}
                    series={graphData.series}
                    type="bar"
                    width="100%"
                    height="94%"
                />
            )}
        </div>
    );
};

export default DataPerMonthGraph;
