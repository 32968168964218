import React, { useContext, useEffect, useState } from "react";

import { dashboardContext } from "../context/DashboardContext";

import "../static/scss/dashboard.scss";
import TaskButton from "../components/mobile/task/TaskButton";

const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dashboardStore = useContext(dashboardContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            dashboardStore.refetchData(true);
            setIsLoaded(true);
        }
    }, [dashboardStore, isLoaded, setIsLoaded]);

    return (
        <div>
            {children}
            <TaskButton />
        </div>
    );
};

export default DashboardLayout;
