export type FooterLink = {
    label: string;
    href: string;
};

export const FooterSubscription = ({
    links,
    companyName
}: {
    links: FooterLink[];
    companyName: string;
}) => {
    return (
        <footer>
            {companyName}
            {" | "} {/* TODO only before css implem */}
            {links.map((link: FooterLink, index: number) => (
                <a key={index} href={link.href}>
                    {link.label}
                    {" | "} {/* TODO only before css implem*/}
                </a>
            ))}
        </footer>
    );
};
