import React from "react";

import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Route, RouteComponentProps, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { Plugin } from "./pluginRouter";
import PrivateRoute from "./privateRoute";
import RootRedirect from "./rootRedirect";
import { DragNDropProvider } from "../components/dragNDrop/DragNDropContext";
import TaskManagerDetail from "../components/mobile/task-manager-detail/TaskManagerDetail";
import { TableProvider } from "../components/table/customTable/context/tableContext";
import { AuthLayoutProvider } from "../context/authLayoutContext";
import { CustomCalendarProvider } from "../context/calendarContext";
import { ClientProvider } from "../context/clientContext";
import { ClientServicesProvider } from "../context/clientServicesContext";
import { ConsumptionProvider } from "../context/consumptionContext";
import { DashboardProvider } from "../context/DashboardContext";
import { HistoryCallProvider } from "../context/historyCallContext";
import { LicensesProvider } from "../context/licensesContext";
import { ServicesProvider } from "../context/servicesContext";
import { ServiceManagementProvider } from "../context/ServicesManagementContext";
import { SocketProvider } from "../context/socketContext";
import { TaskManagementProvider } from "../context/TaskManagementContext";
import AccountLayout from "../layouts/accountLayout";
import AuthLayout from "../layouts/authLayout";
import DashboardLayout from "../layouts/dashboardLayout";
import PluginPageLayout from "../layouts/pluginPageLayout";
import { SubmissionLayout } from "../layouts/submissionLayout";
import EditAccountFactory from "../pages/account/editAccountFactory";
import MyAccount from "../pages/account/myAccount";
import MyInvoices from "../pages/account/plan&payment/Invoices";
import { PlanAndPayment } from "../pages/account/plan&payment/PlanAndPayment";
import CreatePassword from "../pages/auth/createPassword/createPassword";
import ForgottenPassword from "../pages/auth/forgottenPassword/forgottenPassword";
import Login from "../pages/auth/login/login";
import LoginPlugin from "../pages/auth/plugin/loginPlugin";
import LoginPluginSuccess from "../pages/auth/plugin/loginPluginSuccess";
import { Redirection } from "../pages/auth/redirection";
import ResetPassword from "../pages/auth/resetPassword/resetPassword";
import Dashboard from "../pages/dashboard/dashboard";
import { Developer } from "../pages/developer/developer";
import { Home } from "../pages/home/home";
import { PlansComponent } from "../pages/plans/plansComponent";
import { UpdateTrial } from "../pages/plans/updateTrial";
import {
    CheckInboxTrialstates,
    signUpTrialstates
} from "../pages/subscription/ressources/headerStates";
import { SubscritpionSucceedPage } from "../pages/subscription/subscriptionSucceeded";
import { TrialSubscription } from "../pages/subscription/trialSubscription";
import { Anonymization } from "../pages/taskManager/pages/anonymization/Anonymization";
import { RefsProvider } from "../pages/taskManager/pages/studio/contexts/RefsContexts";
import { Studio } from "../pages/taskManager/pages/studio/Studio";
import TaskManager from "../pages/taskManager/taksManager";
import ScrollToTop from "../services/ScrollToTop";

const Routes: React.FC = () => {
    return (
        <CookiesProvider>
            {/** @ts-expect-error browser router pue un peu du cul là */}
            <BrowserRouter>
                <ScrollToTop />
                <ClientServicesProvider>
                    <ServicesProvider>
                        <ConsumptionProvider>
                            <SocketProvider>
                                <HistoryCallProvider>
                                    <Switch>
                                        <Route
                                            exact
                                            path="/signup/usage/trial"
                                            render={() => (
                                                <SubmissionLayout
                                                    accountCreationStep={signUpTrialstates}
                                                >
                                                    <TrialSubscription />
                                                </SubmissionLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/signup/usage/trial/succeed"
                                            render={() => (
                                                <SubmissionLayout
                                                    accountCreationStep={CheckInboxTrialstates}
                                                >
                                                    <SubscritpionSucceedPage />
                                                </SubmissionLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/login"
                                            component={(props: RouteComponentProps) => (
                                                <AuthLayoutProvider>
                                                    <AuthLayout {...props}>
                                                        <Login />
                                                    </AuthLayout>
                                                </AuthLayoutProvider>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/login/plugin"
                                            component={() => <LoginPlugin />}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/login/plugin/success"
                                            layout={null}
                                            component={() => <LoginPluginSuccess />}
                                        />
                                        <Route
                                            exact
                                            path="/redirection"
                                            component={() => <Redirection />}
                                        />
                                        <Route
                                            exact
                                            path="/forgotten-password"
                                            component={(props: RouteComponentProps) => (
                                                <AuthLayoutProvider>
                                                    <AuthLayout {...props}>
                                                        <ForgottenPassword />
                                                    </AuthLayout>
                                                </AuthLayoutProvider>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/create-password"
                                            component={(props: RouteComponentProps) => (
                                                <AuthLayoutProvider>
                                                    <AuthLayout {...props}>
                                                        <CreatePassword />
                                                    </AuthLayout>
                                                </AuthLayoutProvider>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/reset-password"
                                            component={(props: RouteComponentProps) => (
                                                <AuthLayoutProvider>
                                                    <AuthLayout {...props}>
                                                        <ResetPassword />
                                                    </AuthLayout>
                                                </AuthLayoutProvider>
                                            )}
                                        />
                                        <Route exact path="/" component={RootRedirect} />

                                        {/* DASHBOARD */}
                                        <PrivateRoute
                                            exact
                                            path="/dashboard"
                                            providers={[
                                                ClientProvider,
                                                DashboardProvider,
                                                ServiceManagementProvider,
                                                CustomCalendarProvider
                                            ]}
                                            component={(props: RouteComponentProps) => (
                                                <DashboardLayout {...props}>
                                                    {<Dashboard />}
                                                </DashboardLayout>
                                            )}
                                        />
                                        {/* ACCOUNT */}
                                        <PrivateRoute
                                            exact
                                            path={["/account"]}
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    {<MyAccount />}
                                                </AccountLayout>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/account/invoice"
                                            providers={[ClientProvider, TableProvider]}
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    {<MyInvoices />}
                                                </AccountLayout>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/account/developer"
                                            providers={[ClientProvider]}
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    <Developer />
                                                </AccountLayout>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path={[
                                                "/account/plan",
                                                "/account/plan/update-payment-method-success"
                                            ]}
                                            providers={[ClientProvider, TableProvider]}
                                            component={(props: RouteComponentProps) => (
                                                <AccountLayout {...props}>
                                                    <PlanAndPayment />
                                                </AccountLayout>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/account/plan/update"
                                            providers={[ClientProvider]}
                                            component={PlansComponent}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/account/plan/update/trial"
                                            providers={[ClientProvider]}
                                            component={UpdateTrial}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/account/edit/:editKey"
                                            component={EditAccountFactory}
                                        />
                                        {/* TASK MANAGER */}
                                        <PrivateRoute
                                            exact
                                            path="/task-manager"
                                            providers={[
                                                ClientProvider,
                                                TableProvider,
                                                TaskManagementProvider
                                            ]}
                                            component={TaskManager}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/task-manager/anonymization/edit/:jobId"
                                            render={() => (
                                                <RefsProvider>
                                                    <RecoilRoot override={true}>
                                                        <Studio />
                                                    </RecoilRoot>
                                                </RefsProvider>
                                            )}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/task-manager/:jobId/detail"
                                            providers={[ClientProvider, TaskManagementProvider]}
                                            component={TaskManagerDetail}
                                        />

                                        {/** PLANS */}
                                        <PrivateRoute
                                            exact
                                            path="/account/plan"
                                            component={PlansComponent}
                                        />

                                        {/** PLUGIN NESTED ROUTER */}
                                        <PrivateRoute
                                            path="/plugin"
                                            component={Plugin}
                                            providers={[ClientProvider, LicensesProvider]}
                                            layout={PluginPageLayout}
                                        />
                                        <Route path={["/home", "/task-manager/anonymization"]}>
                                            <DragNDropProvider
                                                maxSize={Number(
                                                    process.env.REACT_APP_MAX_FILE_SIZE
                                                )} // 8GB
                                                accepts={
                                                    "image/jpeg, image/jpg, image/png, image/webp, application/zip, video/mp4, application/x-zip-compressed, video/avi, .mkv, .MKV, video/quicktime, video/x-msvideo"
                                                }
                                            >
                                                <Switch>
                                                    <PrivateRoute
                                                        exact
                                                        path="/home"
                                                        component={Home}
                                                        providers={[
                                                            ClientProvider,
                                                            TaskManagementProvider,
                                                            TableProvider,
                                                            ClientProvider
                                                        ]}
                                                    />
                                                    <PrivateRoute
                                                        exact
                                                        path="/task-manager/anonymization"
                                                        providers={[
                                                            ClientProvider,
                                                            TaskManagementProvider
                                                        ]}
                                                        component={Anonymization}
                                                    />
                                                </Switch>
                                            </DragNDropProvider>
                                        </Route>
                                        <Route path="*" component={RootRedirect} />
                                    </Switch>
                                </HistoryCallProvider>
                            </SocketProvider>
                        </ConsumptionProvider>
                    </ServicesProvider>
                </ClientServicesProvider>
            </BrowserRouter>
        </CookiesProvider>
    );
};

export default Routes;
