import React from "react";

// Style
import "./skeleton.scss";

interface InterfaceSkeletonLabel {
    minHeight?: string;
    height?: string;
    minWidth?: string;
    width?: string;
    label: string;
}

export const SkeletonLabel: React.FC<InterfaceSkeletonLabel> = ({
    minHeight,
    height,
    minWidth,
    width,
    label
}) => {
    return (
        <span className={`skeleton-label`} style={{ minHeight, height, minWidth, width }}>
            {label}
        </span>
    );
};

interface InterfaceSkeletonPlaceholder {
    minHeight?: string;
    height?: string;
    minWidth?: string;
    width?: string;
    style?: "default" | "border" | "block";
}

export const SkeletonPlaceholder: React.FC<InterfaceSkeletonPlaceholder> = ({
    minHeight = "14px",
    height,
    minWidth = "100px",
    width,
    style = "default"
}) => {
    return (
        <span
            className={`skeleton-placeholder style-${style}`}
            style={{ minHeight, height, minWidth, width }}
        />
    );
};

interface InterfaceSkeletonGraph {
    colCount?: number;
    labelCount?: number;
}

export const SkeletonGraph: React.FC<InterfaceSkeletonGraph> = ({
    colCount = 40,
    labelCount = 2
}) => {
    return (
        <div className="skeleton--graph--container">
            <div className={`skeleton--graph`}>
                <div className="skeleton--graph__shimmer">
                    {Array.from({ length: colCount }).map((_, index) => (
                        <React.Fragment key={index}>
                            <div key={index} className={`top top--${index + 1}`}></div>
                            <div key={index + colCount} className={`sep sep--${index + 1}`}></div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="skeleton--graph__label">
                {Array.from({ length: labelCount }).map((_, index) => (
                    <div key={index}>
                        <label className={`label`} key={index}></label>
                    </div>
                ))}
            </div>
        </div>
    );
};
