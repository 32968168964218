import { FooterSubscription, FooterLink } from "../components/footer/FooterSubscription";
import { HeaderStatus, HeaderSubscription } from "../components/header/HeaderSubscription";

const links: FooterLink[] = [
    {
        label: "TERM OF USE",
        href: `${process.env.REACT_APP_BLURIT_LANDING_FRONT_ENDPOINT}/terms-of-use`
    },
    {
        label: "PRIVACY POLICY",
        href: `${process.env.REACT_APP_BLURIT_LANDING_FRONT_ENDPOINT}/privacy-policy`
    },
    {
        label: "COOCKIES POLICY",
        href: `${process.env.REACT_APP_BLURIT_LANDING_FRONT_ENDPOINT}/cookies-policy`
    },
    {
        label: "LEGAL NOTICE",
        href: `${process.env.REACT_APP_BLURIT_LANDING_FRONT_ENDPOINT}/legal-notice`
    }
];

const currentDate = new Date(Date.now());

const footerLinks = {
    companyName: `© ${currentDate.getFullYear()} WASSA`,
    links: links
};

export const SubmissionLayout = ({
    children,
    accountCreationStep
}: {
    children: React.ReactNode;
    accountCreationStep: HeaderStatus[];
}) => {
    return (
        <>
            <HeaderSubscription accountCreationStep={accountCreationStep} />
            {children}
            <FooterSubscription {...footerLinks} />
        </>
    );
};
